import { createRouter, createWebHistory } from 'vue-router'
import Login from "../views/Login";
import Home from "../views/Home";
import TrainingCard from "../views/TrainingCard";
import Certificates from "../views/Certificates";
import Certificate from "../views/Certificate";
import Contact from "../views/Contact";
import EditProfile from "../views/EditProfile";
import store from '@/store'
import NotCompleteProfile from "@/views/NotCompleteProfile";
import ExternalCertificate from "../views/ExternalCertificate";

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/training-card',
    name: 'TrainingCard',
    component: TrainingCard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/certificates',
    name: 'Certificates',
    component: Certificates,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/complete-profile',
    name: 'NotCompleteProfile',
    component: NotCompleteProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/certificate/:id',
    props: true,
    name: 'Certificate',
    component: Certificate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/external-certificate/:id',
    props: true,
    name: 'ExternalCertificate',
    component: ExternalCertificate,
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.user.token) {
    next({name: 'Login'})
  } else if(store.state.user.token && to.name === 'Login'){
    next({name: 'Home'})
  } else {
    if(store.state.user && store.state.user.user && !store.state.user.user.avatar && to.name != 'NotCompleteProfile' && to.name != 'EditProfile') {
      next({name: 'NotCompleteProfile'})
    }
    next()
  }
})

export default router
