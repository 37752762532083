<template>
  <div class="wrapper-login">
		<DisabledOfflineBlock v-if="!online"/>
      <div class="wrapper-logo">
        <img src="../assets/logo.png" alt="">
      </div>
      <div class="wrapper-form-login">
          <div class="form-element">
              <label for="login" >Email</label>
              <input id="login" @input="clearError" v-model="email" :class="{'is-invalid':error}" type="text">
              <div class="invalid-feedback">
                  {{error}}
              </div>
          </div>
          <div class="form-element">
              <label for="password">Password</label>
              <input id="password" @input="clearError" v-model="password" type="password">
          </div>
          <div class="wrapper-forgot">
              <a target="_blank" href="https://academy.midfix.co.uk/password/reset">Forgotten your password?</a>
          </div>
          <DefaultButton
              @press="login"
              :title="`Login`"
          />
      </div>
  </div>
</template>

<script>
import DefaultButton from "../components/DefaultButton";
import router from "../router";
import DisabledOfflineBlock from "../components/DisabledOfflineBlock";

export default {
    name: 'Login',
    components: {
        DefaultButton,
		DisabledOfflineBlock
    },
    data() {
        return {
            email: '',
            password: '',
            error: ''
        }
    },
    methods: {
        login() {
            this.$store.dispatch("showPreloader")
            this.error = '';
            let vm = this;
            let email = this.email
            let password = this.password
            this.$store
                .dispatch("login", {
                    email, password
                })
                .then(() => {
                    router.push({name: 'Home'})
                })
                .catch(err => {
					if(err.response){
						vm.error = err.response.data.message
					}
                    this.$store.dispatch("hidePreloader")
                })
        }
    },
	computed: {
		online() {
			return this.$store.state.online
		},
	},
}
</script>

<style scoped lang="scss">
    .wrapper-login {
        height: 100vh;
        background-color: #1F3E63;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 35px;
		position: relative;
        .wrapper-logo {
            margin-bottom: 40px;
            max-width: 500px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            img {
                width: 100%;
                max-width: 100%;
            }
        }
        .wrapper-forgot {
            text-align: right;
            margin-bottom: 22px;
            a {
                font-size: 14px;
                line-height: 1.7px;
                color: #FFFFFF;
            }

        }
        .wrapper-form-login {
            max-width: 500px;
            margin: 0 auto;
            width: 100%;
        }
    }
</style>
