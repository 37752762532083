<template>
	<div class="home">
		<div class="wrapper-content-not-info">
			<span>
				We could not generate training card for you as we can’t find your photo.
				<br> Please
				<router-link
					:to="{ name: 'EditProfile'}"
				>
					upload
				</router-link>
				a profile photo.
<!--				<br> Please go to <a :href="baseURL + '/cabinet/profile/edit'" target="_blank">{{baseURL}}</a> and upload a profile photo.-->
				<br>
				Or you can to go
				<a :href="baseURL + '/cabinet/profile/edit'" target="_blank">MIDFIX Academy</a>
				and upload a profile photo.
			</span>
			<DefaultButton
				:title="`Back to login`"
				@press="logout()"
				></DefaultButton>
		</div>
	</div>
</template>

<script>
import DefaultButton from "../components/DefaultButton";

export default {
    name: 'NotCompleteProfile',
    components: {
        DefaultButton
    },
	data() {
		return {
			baseURL: process.env.VUE_APP_BASE_URL,
		}
	},
	methods: {
		logout () {
			this.$store.dispatch('logout')
		}
	},
	mounted() {
		this.$store.dispatch("hidePreloader")
	}
}
</script>

<style lang="scss">
	.wrapper-content-not-info {
		min-height:100vh;
		background-color: #1F3E63;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 35px;
		span {
			color: #fff;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.7;
			margin-bottom: 1px;
			text-align: center;
			a {
				color: #FDC300;
			}
		}
		.default-btn {
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
			span {
				color: #000;
				font-size: 16px;
				line-height: 1.625;
			}
		}
	}
</style>
