<template>
  <div class="home">
      <Header
        :btnBackShow="true"
      >
          <router-link :to="{name: 'Certificates'}" class="header-back-link">
              <div class="wrapper-back">
                  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.71583L7.28417 0L0 7.28417L7.28417 14.5683L9 12.8525L3.43165 7.28417L9 1.71583Z" fill="black"/>
                  </svg>
                  <span class="text-back">Back</span>
              </div>
          </router-link>
      </Header>
      <div class="wrapper-content-certificate" v-if="getUser && certificate">
        <div class="card-certificate">
            <div class="wrapper-logo">
                <img src="../assets/logo-blue.png" alt="">
            </div>
            <div class="main-title-wrapper">
                <p class="first-title">
                    CERTIFICATE
                </p>
                <p class="second-title">
                    OF TRAINING
                </p>
            </div>
            <div class="wrapper-user-certificate">
                <p class="text">
                    This certificate is presented to:
                </p>
                <p class="name">
                    {{getUser.full_name}}
                </p>
                <p class="description">
                    For attending MIDIFIX {{certificate.name}} on {{trainingDate}}. The following topics were covered in the presentation:
                </p>
                <ul v-if="certificate.chapters.data" class="list">
                    <li v-for="(chapter, index) in certificate.chapters.data" :key="index">
                        {{chapter.name}}
                    </li>
                </ul>
            </div>
            <div v-if="certificate.trainer">
                <div class="wrapper-signature" v-if="certificate.trainer.signature">
                    <img :src="baseURL + certificate.trainer.signature" alt="">
                </div>
                <p class="name-signature">
                    {{certificate.trainer.name}}
                </p>
            </div>

        </div>
        <div class="share-buttons">
          <DefaultButton
              @press="shareViaWebShare"
              :title="`Share`"
          />
          <DefaultButton
              @press="downloadPdf"
              :title="`Download`"
          />
        </div>

      </div>
		<div class="not-have-content-certificate" v-if="!successCacheCertificate">
			<p>
				You are currently using the offline version of the application, so the content of this page is not available to you.
				<br>
				Post this page online so that the content is available offline.
			</p>
		</div>
  </div>
</template>

<script>
import Header from "../components/Header";
import DefaultButton from "../components/DefaultButton";
export default {
    name: 'Certificate',
    components: {
        Header,
        DefaultButton
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_URL,
			successCacheCertificate: true,
        }
    },
    methods: {
        getCertificate() {
            let id = this.$route.params;
            this.$store.dispatch("getCertificate", {
                id
			}).catch(() => {
				this.successCacheCertificate = false
			})
        },
		cleatCertificate() {
            this.$store.dispatch("clearCertificate")
        },
		shareViaWebShare() {
			if(navigator.share) {
				navigator.share({
					title: 'MIDFIX Academy',
					text: 'Certificate ' + this.certificate.name,
					url: this.baseURL + '/share/certificate/' + this.getUser.uuid + '/' + this.certificate.id,
				})
			}else {
				let link = this.baseURL + '/share/certificate/' + this.getUser.uuid + '/' + this.certificate.id;
				navigator.clipboard.writeText(link);
				this.$store.dispatch("showNotificationCopied")
			}
		},
		downloadPdf() {
      let link = this.baseURL + '/share/certificate-pdf/' + this.getUser.uuid + '/' + this.certificate.id;
      window.open(link, '_blank');
		}
    },
    computed: {
        getUser() {
            return this.$store.state.user.user
        },
        certificate() {
            return this.$store.state.user.certificate
        },
		trainingDate() {
			let course = this.$store.state.user.courses.data.filter(course => course.course_id == this.$store.state.user.certificate.id);
			return course[0] ? course[0].training_date_format : ''
		}
    },
	created() {
		const CoursesInfo = localStorage.getItem('courses')
		if (CoursesInfo) {
			const CoursesData = JSON.parse(CoursesInfo)
			this.$store.commit('setCourses', CoursesData)
		}
	},
    mounted() {
		this.cleatCertificate()
        this.getCertificate()
    }
}
</script>

<style lang="scss">
    .wrapper-content-certificate {
        margin: 20px;
        .card-certificate {
            background-color: #fff;
            padding: 10px;
            height: 70vh;
            max-height: 70vh;
            overflow-y: auto;
            .main-title-wrapper {
                margin-top: 25px;
                margin-bottom: 20px;
                .first-title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #1B3E67;
                }
                .second-title {
                    font-size: 18px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #1B3E67;
                }
            }
            .wrapper-user-certificate {
                margin-top: 20px;
                .text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                }
                .name {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: #1B3E67;
                }
                .description {
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    margin-bottom: 10px;
                }
                .list {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    list-style: disc;
                    padding-left: 18px;
                }
            }
            .wrapper-signature {
                margin-top: 20px;
                margin-bottom: 10px;

                img {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #1F3E63;
                }
            }
            .name-signature {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }
        }
		a {
			text-decoration: none;
		}
        .default-btn {
            margin-top: 77px;
        }
    }
	.not-have-content-certificate {
		height: calc(100vh - 110px);
		width: 100%;
		padding: 15px;
		background-color: #a9a9a9;
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			color: #fff;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
		}
	}
    @media screen and (min-width: 768px) {
        .wrapper-content-certificate {
            margin: 40px;
            .card-certificate {
                height: auto;
                max-height: unset;
                padding: 25px;
                .wrapper-logo {
                    width: 300px;
                    img {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
            .default-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .share-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 12px;
    }
</style>
