<template>
	<div class="wrapper-install-not-ios">
		<div class="wrapper-text">
			<svg  @click="closeInstall" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0596 1.44328L13.6163 0L7.53 6.08629L1.44371 0L0.000427246 1.44328L6.08672 7.52957L0.000427246 13.6159L1.44371 15.0591L7.53 8.97285L13.6163 15.0591L15.0596 13.6159L8.97328 7.52957L15.0596 1.44328Z" fill="black"></path></svg>
			<p class="text-install">
				You can install the application for more comfortable use, and access to all the functionality!
			</p>
			<div class="wrapper-btns">
				<div class="button-install-app" @click="install">
					Install
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'PromoteInstallNotIOS',
	data() {
		return {
			deferredPrompt: null
		}
	},
	created() {
		window.addEventListener("beforeinstallprompt", e => {
			e.preventDefault();
			this.deferredPrompt = e;
		});
	},
	methods: {
		install() {
			this.deferredPrompt.prompt();
		},
		closeInstall() {
			this.$emit('press');
		},
	}
}
</script>

<style lang="scss">
	.wrapper-install-not-ios {
		position: fixed;
		bottom: 10px;
		left: 0px;
		right: 0px;
		z-index:3;
		width: 95%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		background-color: transparent;
		.wrapper-text {
			border-radius: 8px;
			padding: 8px;
			position: relative;
			background-color: #fff;
			svg {
				position: absolute;
				z-index:1;
				top: 7px;
				right: 7px;
				cursor: pointer;
			}
			.text-install {
				margin-bottom: 15px;
				font-size: 14px;
				line-height: 150%;
				padding-right: 20px;
			}
			.wrapper-btns {
				display: flex;
				justify-content: center;
				.button-install-app {
					background-color: #FDC300;
					border-radius: 4px;
					border: 1px solid #FDC300;
					padding: 7px 25px;
					transition: 0.3s;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
</style>
