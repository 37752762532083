import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API,
    withCredentials: true,
})


export default {
    loginUser(user) {
        return apiClient.post('/login' ,user)
    },
    getCourses() {
        return axios.get(`${process.env.VUE_APP_BASE_URL_API}/user/courses`, {withCredentials: true})
    },
    sendMail(data) {
        return axios.post(`${process.env.VUE_APP_BASE_URL_API}/contacts`, data )
    },
    updateProfile(data) {
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        return axios.post(`${process.env.VUE_APP_BASE_URL_API}/user`, data, settings )
    },
    getCertificate(data) {
        return  axios.get(`${process.env.VUE_APP_BASE_URL_API}/courses/${data.id.id}`)
    },
    getExternalCertificate(data) {
        return  axios.get(`${process.env.VUE_APP_BASE_URL_API}/courses/certificate/${data.id.id}`)
    },
    logout() {
        return axios.post(`${process.env.VUE_APP_BASE_URL_API}/logout`)
    }
}
