<template>
  <div class="home">
      <Header
        :btnBackShow="true"
      >
          <router-link :to="{name: 'Home'}" class="header-back-link">
              <div class="wrapper-back">
                  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.71583L7.28417 0L0 7.28417L7.28417 14.5683L9 12.8525L3.43165 7.28417L9 1.71583Z" fill="black"/>
                  </svg>
                  <span class="text-back">Back</span>
              </div>
          </router-link>
      </Header>
      <div class="wrapper-content-profile-edit">
			<DisabledOfflineBlock v-if="!online"/>
          <p class="title">
              Edit Profile
          </p>
          <div class="wrapper-edit-profile">
              <div class="wrapper-photo">
                  <div class="wrapper-avatar">
                      <img :src="avatarView ?  avatarView : (avatar ? baseURL + avatar : require('@/assets/default-avatar-midfix.png'))" alt="">
                  </div>
                  <div class="wrapper-button">
                      <button @click="this.$refs.inputImage.click()" class="change-img" >
                          <span>Update Photo</span>
                      </button>
                      <input hidden ref='inputImage'  type="file" @change="previewImage" accept="image/*">
                      <input hidden ref='inputImage' :class="(errorFormatImg.image || errors.avatar) ? 'is-invalid' : ''" type="file" @change="previewImage" accept="image/*">
                      <div class="invalid-feedback">
                          {{errorFormatImg.image ||  (errors.avatar ? errors.avatar[0]: '')}}
                      </div>
                  </div>
              </div>
              <div class="form-element">
                  <label for="firstName">First name</label>
                  <input id="firstName"  v-model="first_name" :class="(errors.first_name) ? 'is-invalid' : ''" type="text">
                  <div class="invalid-feedback">
                      {{(errors.first_name) ? errors.first_name[0] : ''}}
                  </div>
              </div>
              <div class="form-element">
                  <label for="lastName">Last name</label>
                  <input id="lastName" v-model="last_name"  :class="(errors.last_name) ? 'is-invalid' : ''" type="text">
                  <div class="invalid-feedback">
                      {{(errors.last_name) ? errors.last_name[0] : ''}}
                  </div>
              </div>
              <div class="form-element">
                  <label for="email">Email</label>
                  <input id="email" v-model="email" :class="(errors.email) ? 'is-invalid' : ''" type="email">
                  <div class="invalid-feedback">
                      {{(errors.email) ? errors.email[0] : ''}}
                  </div>
              </div>
          </div>
        <DefaultButton
            :title="`Save`"
            @press="update"
        />
      </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import DefaultButton from "../components/DefaultButton";
import DisabledOfflineBlock from "../components/DisabledOfflineBlock";
export default {
  name: 'EditProfile',
  components: {
    Header,
    DefaultButton,
    DisabledOfflineBlock
  },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_URL,
            avatar: "",
            email: '',
            first_name: '',
            last_name: '',
            avatarView: "",
            errorFormatImg: {},
        }
    },
    methods: {
        previewImage: function(event) {
            var input = event.target;
            if (input.files && input.files[0] && input.files[0].type.split('/')[0] === 'image') {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.avatarView = e.target.result;
                    this.avatar = input.files[0];
                }
                reader.readAsDataURL(input.files[0]);
            } else {
                this.errorFormatImg.image = 'Invalid format!'
            }
        },
        update() {
            this.$store.dispatch("showPreloader")
            let fd = new FormData();
            fd.append('first_name', this.first_name);
            fd.append('last_name', this.last_name);
            fd.append('email', this.email);
            fd.append('_method', 'PUT')
            if(this.avatar) {
                fd.append('avatar', this.avatar);
            }
            this.$store.dispatch("updateProfile", fd)
        },
        updateLocaleInfo() {
            this.avatarView = this.getUser.avatar ? this.baseURL + this.getUser.avatar : ''
            this.first_name = this.getUser.first_name
            this.last_name = this.getUser.last_name
            this.email = this.getUser.email
        }
    },
    computed: {
        notification() {
            return this.$store.state.notification
        },
        errors() {
            return this.$store.state.notification.data;
        },
        getUser() {
            return this.$store.state.user.user
        },
		online() {
			return this.$store.state.online
		},
    },
    mounted() {
        this.$store.dispatch("clearErrors")
        this.updateLocaleInfo()
    }
}
</script>

<style lang="scss">
    .wrapper-content-profile-edit {
        padding: 20px;
        background-color: #fff;
        min-height: 100vh;
		position: relative;
        .title {
            font-weight: 700;
            font-size: 24px;
            letter-spacing: -1px;
            color: #1B3E67;
            padding-bottom: 15px;
            border-bottom: 1px solid #000000;
        }
        .wrapper-edit-profile {
            margin-top: 10px;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            .wrapper-photo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .wrapper-avatar {
                    width: 70px;
                    min-width: 70px;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    border-radius: 50%;
                    overflow: hidden;
                    align-items: center;
                    img {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                .wrapper-button {
                    width: calc(100% - 90px);
                    button {
                        background-color: transparent;
                        border-radius: 4px;
                        border: 1px solid #1B3E67;
                        padding: 7px 6px;
                        width: 100%;
                        transition: 0.3s;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span {
                            color: #000;
                            font-size: 16px;
                            line-height: 1.625;
                        }
                    }
                }
            }
            .form-element {
                margin-top: 20px;
                label {
                    font-size: 14px;
                    line-height: 24px;
                    color: #1B3E67;
                }
                input {
                    border: 1px solid #1B3E67;
                    border-radius: 2px;
                    font-size: 14px;
                    line-height: 1.7;
                    padding: 10px;
                }
                textarea {
                    border: 1px solid #1B3E67;
                    font-size: 14px;
                    line-height: 1.7;
                    padding: 10px;
                }
                .checkbox-control {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000000;
                    display: flex;
                    input[type="checkbox"] {
                        appearance: none;
                        background-color: #fff;
                        margin: 0;
                        font: inherit;
                        color: #1B3E67;
                        width: 20px;
                        height: 20px;
                        border: 2px solid #1B3E67;
                        border-radius: 4px;
                    }
                    input[type="checkbox"] {
                        display: grid;
                        place-content: center;
                        margin-right: 7px;
                    }
                    input[type="checkbox"]::before {
                        content: "";
                        width: 16px;
                        height: 16px;
                        transform: scale(0);
                        transition: 120ms transform ease-in-out;
                        box-shadow: inset 16px 16px #FDC300;
                        background-color: #FDC300;
                    }
                    input[type="checkbox"]:checked::before {
                        transform: scale(1);
                    }
                    input[type="checkbox"]:focus {
                        outline: 1px solid transparent;
                        outline-offset: 1px;
                    }

                }
            }
        }

        .default-btn {
            margin-top: 77px;
        }
    }
    @media screen and (min-width: 768px) {
        .wrapper-content-profile-edit {
            padding: 40px;
            .default-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
</style>
