<template>
  <p class="empty-text">{{text}}</p>
</template>

<script>
export default {
  name: 'EmptyText',
  props: {
      text: {
          type: String,
          default: 'No data available'
      }
  }
}
</script>

<style lang="scss">
    .empty-text {
		text-align: center;
		margin-top: 30px;
		font-size: 18px;
		color: darkgrey;
		line-height:150%
	}
</style>
