<template>
  <div class="home">
      <Header
              :btnBackShow="true"
      >
          <router-link :to="{name: 'Home'}" class="header-back-link">
              <div class="wrapper-back">
                  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.71583L7.28417 0L0 7.28417L7.28417 14.5683L9 12.8525L3.43165 7.28417L9 1.71583Z" fill="black"/>
                  </svg>
                  <span class="text-back">Back</span>
              </div>
          </router-link>
      </Header>
      <div class="wrapper-content-certificates">
        <p class="title">
            Certificates
        </p>
          <div v-if="completedCourses.length > 0" class="wrapper-certificates">
              <div v-for="(course, index) in completedCourses" :key="index" class="certificate">
                  <p class="name">
                      {{course.name}}
                  </p>
                  <router-link v-if="course.course_id" :to="{name: 'Certificate', params: { id: course.course_id}}" >
                      <DefaultButton
                              :title="`View Certificate`"
                      />
                  </router-link>
                <router-link v-else :to="{name: 'ExternalCertificate', params: { id: course.certificate_id}}" >
                  <DefaultButton
                      :title="`View Certificate`"
                  />
                </router-link>
              </div>
          </div>
			<EmptyText
				v-else
				:text="`You have not received any certificate yet.`"
			/>
      </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import DefaultButton from "../components/DefaultButton";
import EmptyText from "../components/EmptyText";
export default {
    name: 'Certificates',
    components: {
        Header,
        DefaultButton,
		EmptyText
    },
    data() {
        return {
            courses: {}
        }
    },
    computed: {
        completedCourses() {
            if (this.courses && this.courses.data){
                return this.courses.data.filter(course => course.status == 1)
            }
            return this.$store.state.user.courses;

        }
    },
	created() {
		const CoursesInfo = localStorage.getItem('courses')
		if (CoursesInfo) {
			const CoursesData = JSON.parse(CoursesInfo)
			this.$store.commit('setCourses', CoursesData)
		}
	},
    mounted() {
        this.courses = this.$store.state.user.courses
    }
}
</script>

<style lang="scss">
    .wrapper-content-certificates {
        padding: 20px;
        .title {
            font-weight: 700;
            font-size: 24px;
            letter-spacing: -1px;
            color: #1B3E67;
            padding-bottom: 15px;
            border-bottom: 1px solid #000000;
        }
        .wrapper-certificates {
            display: flex;
            flex-direction: column;
            .certificate {
                display: flex;
                flex-direction: column;
                padding: 20px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                a {
                    text-decoration: none;
                }
                .name {
                    font-weight: 500;
                    font-size: 16px;
                    color: #000000;
                    margin-bottom: 15px;
                }
                .default-btn {
                    max-width: 150px;
                }
            }
        }
    }
</style>
