<template>
  <div class="wrapper-help-install">
    <div class="wrapper-text">
		<svg class="close"  @click="closeInstall" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0596 1.44328L13.6163 0L7.53 6.08629L1.44371 0L0.000427246 1.44328L6.08672 7.52957L0.000427246 13.6159L1.44371 15.0591L7.53 8.97285L13.6163 15.0591L15.0596 13.6159L8.97328 7.52957L15.0596 1.44328Z" fill="black"></path></svg>
        Install this App on your device: tap

        <svg class="icon-menu-iphone" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="19.000000pt" height="27.000000pt" viewBox="0 0 19.000000 27.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,27.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M69 243 c-21 -24 -21 -25 -2 -9 l22 18 4 -78 3 -79 2 79 2 79 23 -19
c20 -17 20 -16 -2 9 -14 15 -25 27 -26 27 -1 0 -12 -12 -26 -27z"/>
                <path d="M0 95 l0 -95 95 0 95 0 0 95 0 95 -37 -2 c-37 -1 -37 -1 -5 -5 l32
-4 0 -85 0 -84 -85 0 -85 0 0 84 0 85 33 4 c31 4 31 4 -5 5 l-38 2 0 -95z"/>
            </g>
        </svg>
        and then Add to homescreen.
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallHelp',
	methods: {
		closeInstall() {
			this.$emit('press');
		},
	}
}
</script>

<style lang="scss">
  .wrapper-help-install {
      position: fixed;
      bottom: 10px;
      left: 0px;
      right: 0px;
		z-index:3;
      width: 95%;
      margin: 0 auto;
      background-color: transparent;

      /*overflow: hidden;*/
    .wrapper-text {
        border-radius: 8px;
        padding: 8px;
        position: relative;
        background-color: #fff;
        font-size: 14px;
        line-height: 150%;
		.close {
			position: absolute;
			z-index:1;
			top: 7px;
			right: 7px;
			cursor: pointer;
		}
        .icon-menu-iphone {
            width: 17px;
            height: 21px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            width: 15px;
            height: 15px;
            background-color: #fff;
            transform: rotate(45deg);
            bottom: -8px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
  }
</style>
