
export default {
    user: {
      data: {},
      courses: {},
      certificate: null,
      token: sessionStorage.getItem("TOKEN"),
    },
    notification: {
        data: {}
    },
    preloader: {
    status: false,
    text: 'Loading...'
    },
    online: true,
}
