<template>
  <div class="home">
      <Header
              :btnBackShow="true"
      >
          <router-link :to="{ name: 'Home' }" class="header-back-link">
              <div class="wrapper-back">
                  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.71583L7.28417 0L0 7.28417L7.28417 14.5683L9 12.8525L3.43165 7.28417L9 1.71583Z" fill="black"/>
                  </svg>
                  <span class="text-back">Back</span>
              </div>
          </router-link>
      </Header>
        <div class="wrapper-content-card">
			<agile
					:dots="true"
					:infinite="false"
					:nav-buttons="false">
				<div class="slide">
					<div class="head-tab-front">
						<div class="wrapper-logo-tab">
							<img src="../assets/logo-blue.png" alt="">
						</div>
						<div class="name-card">
							<p>Installer Training</p>
						</div>
					</div>
					<div class="wrapper-user-info">
						<div class="wrapper-avatar-user">
							<img :src="getUser.avatar ? baseURL + getUser.avatar : require('@/assets/default-avatar-midfix.png')" alt="">
						</div>
						<div class="wrapper-info">
							<div class="info-block">
								<p class="title">Name:</p>
								<p class="value">{{getUser.first_name}}</p>
							</div>
							<div class="info-block">
								<p class="title">Surname:</p>
								<p class="value">{{getUser.last_name}}</p>
							</div>
							<div class="info-block">
								<p class="title">D.O.B.</p>
								<p class="value">{{getUser.birthday ?? 'Date'}}</p>
							</div>
							<div class="info-block">
								<p class="title">Company</p>
								<p class="value">{{getUser.company}}</p>
							</div>
						</div>
					</div>
					<div class="wrapper-dates">
						<div class="block-date">
							<span class="name">Training Date:</span>
							<span class="date">{{getUser.training_date}}</span>
						</div>
						<div class="block-date">
							<span class="name">Expiry Date:</span>
							<span class="date">{{getUser.expiry_date}}</span>
						</div>
					</div>
					<div class="wrapper-description">
						<p class="description">
							The person named above has successfully attended and completed the MIDIFIX Academy installer training on the following anchors (see reverse)
						</p>
					</div>
				</div>
				<div class="slide">
					<div class="wrapper-table">
						<table>
							<thead>
								<tr>
									<th>Anchor Type</th>
									<th>Training Date</th>
									<th>Expiry Date</th>
								</tr>
							</thead>
							<tbody v-if="completedCourses && completedCourses.length > 0">
								<tr v-for="(course, index) in completedCourses" :key="index" >
									<td>{{course.name}}</td>
									<td>{{course.training_date}}</td>
                                    <td>{{course.expiry_date}}</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr >
									<td colspan="3">
										<EmptyText
												:text="`You have not completed any course yet.`"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="wrapper-qr-code">
						<div class="wrapper-logo-qr">
							<img src="../assets/logo-blue.png" alt="">
						</div>
					</div>
				</div>
			</agile>
			<DefaultButton
				@press="shareViaWebShare"
				:title="`Share`"
			/>
        </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import DefaultButton from "../components/DefaultButton";
import { VueAgile } from 'vue-agile';
import EmptyText from "../components/EmptyText";
export default {
    name: 'TrainingCard',
    components: {
      Header,
      DefaultButton,
      agile: VueAgile,
		EmptyText
	},
    data() {
        return {
            courses: {},
            baseURL: process.env.VUE_APP_BASE_URL
        }
    },
	methods: {
		shareViaWebShare() {
			if(navigator.share) {
				navigator.share({
					title: 'MIDFIX Academy',
					text: 'Training Card ' + this.getUser.full_name,
					url: this.baseURL + '/share/training-card/' + this.getUser.uuid,
				})
			}else {
				let link = this.baseURL + '/share/training-card/' + this.getUser.uuid;
				navigator.clipboard.writeText(link);
				this.$store.dispatch("showNotificationCopied")
			}
		}
	},
    computed: {
        getUser() {
            return this.$store.state.user.user
        },
        completedCourses() {
            if (this.courses.data){
                return this.courses.data.filter(course => course.status == 1)
            }
            return this.$store.state.user.courses;
        }
    },
	created() {
		const CoursesInfo = localStorage.getItem('courses')
		if (CoursesInfo) {
			const CoursesData = JSON.parse(CoursesInfo)
			this.$store.commit('setCourses', CoursesData)
		}
	},
    mounted() {
        this.courses = this.$store.state.user.courses
    }
}
</script>

<style lang="scss">
    .wrapper-content-card {
        padding: 20px;
		.agile {
			-ms-touch-action: pan-y;
			touch-action: pan-y;
		}
		.agile__list {
			display: flex;
			flex-direction: column-reverse;
			margin-bottom: 30px;
			.slide {
				margin-bottom: 30px;
				height: 70vh;
				padding: 10px;
				max-height: 70vh;
				overflow-y: auto;
				background-color: #fff;
				.head-tab-front {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 15px;
					.wrapper-logo-tab {
						width: 42%;
						img {
							width: 100%;
							max-width: 100%;
						}
					}
					.name-card {
						p {
							font-weight: 700;
							font-size: 16px;
							letter-spacing: -1px;
							text-transform: uppercase;
							color: #1B3E67;
						}
					}
				}
				.wrapper-user-info {
					display: flex;
					justify-content: space-between;
					margin-bottom: 25px;
					.wrapper-avatar-user {
						width: 48%;
						img {
							width: 100%;
							max-width: 100%;
						}
					}
					.wrapper-info {
						width: 49%;
						.info-block {
							margin-bottom: 20px;
							.title {
								font-weight: 700;
								font-size: 16px;
								letter-spacing: -1px;
								color: #1B3E67;
							}
							.value {
								font-weight: 500;
								word-break: break-word;
								font-size: 16px;
								padding-top: 6px;
								color: #000000;
							}
						}
					}
				}
				.wrapper-dates {
					margin-bottom: 25px;
					.block-date {
						padding: 15px 0;
						border-top: 0.5px solid #1F3E63;
						display: flex;
						align-items: center;
						&:last-child {
							border-bottom: 0.5px solid #1F3E63;
						}
						.name {
							font-weight: 700;
							font-size: 16px;
							letter-spacing: -1px;
							color: #1B3E67;
							width: 51%;
						}
						.date {
							font-weight: 500;
							font-size: 16px;
							color: #000000;
						}
					}
				}
				.wrapper-description {
					.description {
						font-weight: 500;
						font-size: 14px;
						color: #000000;
						line-height: 150%;
					}
				}
				.wrapper-table {
					table {
						width: 100%;
						thead {
							tr {
								border-bottom: 1px solid #000000;
								th {
									text-align: center;
									font-weight: 700;
									font-size: 14px;
									line-height: 30px;
									letter-spacing: -1px;
									color: #1B3E67;
									padding: 5px 8px;
									vertical-align: middle;
									&:first-child {
										text-align: left;
										padding-left: 0;
									}
									&:last-child {
										text-align: right;
										padding-right: 0;
									}
								}
							}
						}
						tbody {
							tr {
								border-bottom: 1px solid rgba(0, 0, 0, 0.2);
								td {
									text-align: center;
									font-weight: 500;
									font-size: 14px;
									color: #000000;
									padding: 15px 8px;
									vertical-align: middle;
									&:first-child {
										padding-left: 0;
										text-align: left;
									}
									&:last-child {
										text-align: right;
										padding-right: 0;
									}
								}
							}
						}
					}
				}
				.wrapper-qr-code {
					margin-top: 30px;
					.wrapper-logo-qr {
						width: 72%;
						margin: 0 auto;
						img {
							max-width: 100%;
							width: 100%;
						}
					}
					.qr {
						margin-top: 30px;
						margin-bottom: 20px;
						display: flex;
						img {
							margin: 0 auto;
						}
					}
				}
			}

		}
		.agile__dots {
			margin-bottom: 40px;
			li {
				margin-left: 5px;
				margin-right: 5px;
				button {
					width: 17px;
					height: 17px;
					border: none;
					border-radius: 50%;
					background: rgba(31, 62, 99, 0.3);
				}
				&.agile__dot--current {
					button {
						width: 17px;
						height: 17px;
						border: none;
						border-radius: 50%;
						background: #1F3E63;
					}
				}

			}
		}
		a {
			text-decoration: none;
		}
    }
    @media screen and (min-width: 768px) {
        .wrapper-content-card {
            padding: 40px;
            .agile__list {
                .slide {
                    padding: 25px;
                    .head-tab-front {
                        flex-direction: column;
                        align-items: flex-start;
                        .wrapper-logo-tab {
                            width: 300px;
                            margin-left: auto;
                            margin-right: auto;
                            margin-bottom: 20px;
                        }
                    }
                    .wrapper-user-info {
                        .wrapper-avatar-user {
                            width: 200px;
                        }
                        .wrapper-info {
                            width: calc(100% - 220px);
                        }
                    }
                    .wrapper-qr-code {
                        .wrapper-logo-qr {
                            width: 300px;
                        }
                    }
                }
            }
            .default-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
</style>
