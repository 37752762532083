
import EventService from "../services/EventService";

export default {

    login({ commit }, user) {
      return EventService.loginUser(user)
          .then(({data}) => {
            commit('setUser', data)
            return data;
          })
    },
    clearErrors({ commit }) {
        commit('clearErrors')
    },
    showPreloader({ commit }) {
        commit('showPreloader')
    },
    hidePreloader({ commit }) {
        commit('hidePreloader')
    },
    closeNotification({ commit }) {
        commit('closeNotification')
    },
    showNotificationCopied({ commit }) {
        commit('showNotification',
            {
                status : 'success',
                message: 'Link copied.'
            })
    },
    getCourses({ commit }) {
        EventService.getCourses()
            .then(({data}) => {
                commit('setCourses', data)
                commit('hidePreloader')
            })
    },
    sendMail({ commit }, data) {
        EventService.sendMail(data)
        .then(({data}) => {
            commit('showNotification', data)
            commit('clearErrors')
            commit('hidePreloader')
        }).catch((res) => {
            commit('hidePreloader')
            commit('showNotification', res.response.data)
        })
    },
    updateProfile({ commit }, data) {
        let infoUser = data;
        EventService.updateProfile(data)
            .then(({data}) => {
                commit('updateUser', infoUser)
                commit('showNotification', data)
                commit('clearErrors')
                commit('hidePreloader')
            }).catch((res) => {
                commit('hidePreloader')
                commit('showNotification', res.response.data)
        })
    },
    clearCertificate({ commit }) {
        commit('clearCertificate')
    },
    getCertificate({ commit }, data) {
      return EventService.getCertificate(data)
          .then(({data}) => {
                commit('setCertificate', data)
          })
    },
    getExternalCertificate({ commit }, data) {
      return EventService.getExternalCertificate(data)
          .then(({data}) => {
                commit('setCertificate', data)
          })
    },
    changeOnlineStatus({ commit }, data) {
        commit('changeOnlineStatus', data)
    },
    logout ({ commit }) {
        return EventService.logout()
            .then(() => {
                commit('clearUserData')
            }).catch(() => {
            commit('clearUserData')
        })
    }

}
