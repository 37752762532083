<template>
  <div class="wrapper-disabled-block">
		<p>
			Actions with this block are not available because you do not have an Internet connection. <br>
			Please check your internet connection.
		</p>
  </div>
</template>

<script>
export default {
    name: 'DisabledOfflineBlock',
}
</script>

<style lang="scss">
	.wrapper-disabled-block {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height:100%;
		background-color: darkgrey;
		opacity: .9;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		p {
			color: #fff;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
		}
	}
</style>
