<template>
  <div
    @click="click"
    class="default-btn"
    :class="{'disabled':disabled}"
  >
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  name: 'DefaultButton',
  props: {
      title: {
          type: String,
          default: 'Press'
      },
      disabled: {
        type: Boolean,
        default: false,
      }
  },
  methods: {
    click() {
        this.$emit('press')
    }
  }
}
</script>

<style lang="scss">
    .default-btn {
        background-color: #FDC300;
        border-radius: 4px;
        border: 1px solid  #FDC300;
        padding: 7px 6px;
        width: 100%;
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            color: #000;
            font-size: 16px;
            line-height: 1.625;
        }
        &:hover {
            background-color: white;
        }
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
        &:hover {
          background-color: #FDC300;
        }
      }
    }
    @media screen and (min-width: 768px) {
      .default-btn {
        max-width: 250px;
        width: 250px;
      }
    }
</style>
