<template>
  <div class="home">
      <Header
        :btnBackShow="false"
      />
      <div v-if="getUser" class="wrapper-content-home">
          <div class="wrapper-user-info">
              <div
                  class="wrapper-avatar"
                  :style="{ backgroundImage: getUser.avatar ? 'url(' + baseURL + getUser.avatar + ')' : 'url(' + require('@/assets/default-avatar-midfix.png') + ')' }"
              >
              </div>
              <div class="info">
                  <div class="info-block">
                      <p class="title">Name</p>
                      <p class="value">{{getUser.full_name}}</p>
                  </div>
                  <div class="info-block">
                      <p class="title">Company</p>
                      <p class="value">{{getUser.company}}</p>
                  </div>
                  <div class="info-block">
                      <p class="title">Expiry Date</p>
                      <p class="value">{{getUser.expiry_date}}</p>
                  </div>
              </div>
          </div>
          <div class="wrapper-main-buttons">
              <router-link :to="{ name: 'TrainingCard' }">
                  <DefaultButton
                          :title="`View Your Training Card`"
                  ></DefaultButton>
              </router-link>

              <router-link :to="{ name: 'Certificates' }">
                  <DefaultButton
                          :title="`View Your Training Certificates`"
                  />
              </router-link>

          </div>
          <div class="wrapper-trainings">
              <p class="title">
                  My Training
              </p>
              <div class="wrapper-table-trainings">
                  <table>
                      <thead>
                          <tr>
                              <th>Course Name</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody v-if="courses && courses.data.length > 0 ">
                          <tr  v-for="(course, index) in courses.data" :key="index">
                              <td>{{course.name}}</td>
                              <td>{{course.status_text}}</td>
                          </tr>
                      </tbody>
						<tbody v-else>
							<tr>
								<td colspan="2">
									<EmptyText
										:text="`You have not completed any course yet.`"
									/>
								</td>
							</tr>
						</tbody>

                  </table>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import DefaultButton from "../components/DefaultButton";
import EmptyText from "../components/EmptyText";

export default {
    name: 'Home',
    components: {
        Header,
        DefaultButton,
		EmptyText
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_URL,
            baseURLApi: process.env.VUE_APP_BASE_URL_API
        }
    },
    methods: {
       getCourses() {
          this.$store.dispatch("getCourses")
      }
    },
    computed: {
        getUser() {
           return this.$store.state.user.user
        },
        courses() {
            return this.$store.state.user.courses
        }
    },
    mounted() {
        this.$store.dispatch("showPreloader")
        this.getCourses()
    }
}
</script>

<style lang="scss">
    .wrapper-content-home {
        margin: 30px 15px;
        .wrapper-user-info {
            display: flex;
            margin-bottom: 15px;
            align-items: flex-start;
            justify-content: space-between;
            .wrapper-avatar {
                width: 47%;
                height: 0;
                position: relative;
                padding-top: 23.5%;
                padding-bottom: 23.5%;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                overflow: hidden;
            }
            .info {
                width: 46%;
                .info-block {
                    margin-bottom: 20px;
                    .title {
                        font-weight: 700;
                        font-size: 16px;
                        letter-spacing: -1px;
                        color: #1B3E67;
                    }
                    .value {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                        word-break: break-all;
                        color: #000000;
                    }
                }
            }
        }
        .wrapper-main-buttons {
            .default-btn {
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
        .wrapper-trainings {
            margin-top: 30px;
            .title {
                font-weight: 700;
                font-size: 24px;
                letter-spacing: -1px;
                color: #1B3E67;
                margin-bottom: 10px;
            }
            .wrapper-table-trainings {
                table {
                    width: 100%;
                    thead {
                        border-bottom: 1px solid #000000;
                        tr {
                            th {
                                font-weight: 700;
                                font-size: 16px;
                                letter-spacing: -1px;
                                color: #1B3E67;
                                text-align: right;
                                padding: 7px 0;
                                &:first-child {
                                    text-align: left;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                            td {
                                padding: 15px 8px;
                                text-align: right;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
								vertical-align: middle;
                                color: #000000;
                                &:first-child {
                                    text-align: left;
									padding-left: 0;
                                }
								&:last-child {
									padding-right: 0;
								}
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 768px) {
        .wrapper-content-home {
            margin: 40px;
            .wrapper-user-info {
                align-items: center;
                .wrapper-avatar {
                    width: 200px;
                    height: 200px;
                    position: relative;
                    padding-top: 0;
                    padding-bottom: 0;
                    background-size: cover;
                    background-position: center;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .info {
                    width: calc(100% - 230px);
                    .info-block {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .wrapper-main-buttons {
                display: flex;
                justify-content: center;
                .default-btn {
                    &:first-child {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
</style>
