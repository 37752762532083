import axios from "axios";

export default {
        setUser(state, userData) {
            state.user = userData
            localStorage.setItem('user', JSON.stringify(userData))
            axios.defaults.headers.common['X-Authorization'] = `Bearer ${userData.token}`
        },
        updateUser(state, userData) {
            state.user.user.first_name = userData.get('first_name')
            state.user.user.last_name = userData.get('last_name')
            state.user.user.email = userData.get('email')
            state.user.user.full_name = state.user.user.first_name + ' ' + state.user.user.last_name
            localStorage.setItem('user', JSON.stringify(state.user))
            if(userData.get('avatar')){
                state.user.user.avatar = `/storage/profile/${state.user.user.id}/avatar.png?version=${Math.round(Date.now() / 1000)}`
                localStorage.setItem('user', JSON.stringify(state.user))
            }

        },
        clearUserData () {
            window.localStorage.clear();
            location.reload()
        },
        setCourses(state, data) {
            state.user.courses = data
            localStorage.setItem('courses', JSON.stringify(data))
        },
        setCertificate(state, data) {
            state.user.certificate = data.data
        },
        clearCertificate(state) {
            state.user.certificate = null
        },
        clearErrors(state) {
            state.notification.data = {}
        },
        showPreloader(state) {
            state.preloader.status = true
        },
        hidePreloader(state) {
            state.preloader.status = false
        },
        closeNotification(state) {
            state.notification = {
                data: state.notification.data
            }
        },
        showNotification(state, data) {
            state.notification = data
            function clearNotification() {
                state.notification = {
                    data: state.notification.data
                }
            }
            setTimeout(clearNotification, 3000);
        },
        changeOnlineStatus(state, data) {
            state.online = data.e
        }
}
