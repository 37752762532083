<template>
	<div>
		<v-offline @detected-condition="checkInternetStatus" >
			<template v-if="!online">
				<div class="wrapper-internet-status">
					<p>There is no internet connection, some functions of the application may not be available!</p>
				</div>
			</template>
		</v-offline>
		<router-view/>
		<InstallHelp   v-if="showInstallMessage" @press="hideShowInstallIOS"/>
		<PromoteInstallNotIOS v-if="showInstallMessageNotIOS" @press="hideShowInstall"/>
		<transition name="fade">
			<Preloader
					v-if="preloader.status"
					:text="preloader.text"
			/>
		</transition>
		<transition name="fade">
			<Notification
					v-if="notification.status"
					:notification="notification"
			/>
		</transition>
	</div>
</template>

<script>
  import InstallHelp from "./components/InstallHelp";
  import PromoteInstallNotIOS from "./components/PromoteInstallNotIOS"
  import Preloader from "./components/Preloader";
  import Notification from "./components/Notification";
  import { VOffline } from 'v-offline';
  import axios from 'axios';
  export default {
    name: 'App',
    components: {
      Notification,
      Preloader,
      InstallHelp,
		PromoteInstallNotIOS,
      VOffline
    },
    data() {
      return {
        showInstallMessage: false,
        showInstallMessageNotIOS: false
      }
    },
    computed: {
      preloader() {
        return this.$store.state.preloader
      },
      online() {
        return this.$store.state.online
      },
      notification() {
        return this.$store.state.notification
      }
    },
    mounted() {
      this.helpInstall()
    },
    created () {
      const userInfo = localStorage.getItem('user')
      if (userInfo) {
        const userData = JSON.parse(userInfo)
        this.$store.commit('setUser', userData)
      }
		window.addEventListener("appinstalled", () => {
			this.showInstallMessageNotIOS = false;
		});
      axios.interceptors.response.use(
              response => response,
              error => {
                if (error.response && error.response.status === 401) {
                  this.$store.dispatch('logout')
                }
                this.$store.dispatch('hidePreloader')
                return Promise.reject(error)
              }
      )
    },
    methods: {
		helpInstall()  {
        const isIos = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test( userAgent );
        }
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
		const isInStandaloneModeDesktop = () => {
			return window.matchMedia('(display-mode: standalone)').matches
		}
		const isInFullScreenModeDesktop = () => {
			return window.matchMedia('(display-mode: fullscreen)').matches
		}

        if (isIos() && !isInStandaloneMode()) {
          this.showInstallMessage = true;
        }else if (!isIos() && (!isInStandaloneModeDesktop() || !isInFullScreenModeDesktop)) {
			this.showInstallMessageNotIOS = true;
		}
      },
		checkInternetStatus(e) {
			this.$store.dispatch("changeOnlineStatus", {e})
		},
		hideShowInstall() {
			this.showInstallMessageNotIOS = false;
		},
		hideShowInstallIOS() {
			this.showInstallMessage = false;
		}
    }
  }
</script>

<style lang="scss">
  body {
    background-color: #F4F5F7;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .form-element {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 1px;
    }
    input {
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 14px;
      line-height: 1.7;
      padding: 10px;
    }
    .invalid-feedback {
      display: none;
      margin-top: 3px;
    }
    .is-invalid ~ .invalid-feedback {
      display: block;
      color: #dc3545;
      font-size: 14px;
    }
    .is-invalid  {
      border-color: #FF5559 !important;
    }
  }
  .wrapper-main-buttons {
    a {
      text-decoration: none;
    }
  }
  .invalid-feedback {
    display: none;
    margin-top: 3px;
  }
  .is-invalid ~ .invalid-feedback {
    display: block;
    color: #dc3545;
    font-size: 14px;
  }
  .is-invalid  {
    border-color: #FF5559 !important;
  }
  .wrapper-internet-status {
    width: 100%;
    padding: 15px;
    background-color: darkgrey;
    p {
      color: #fff;
		font-size: 14px;
		line-height: 150%;
    }
  }

</style>
