<template>
  <div class="header" >
    <slot v-if="btnBackShow"></slot>
    <div
      class="wrapper-icon-header"
      @click="statusHeader = !statusHeader"
    >
      <div class="line-icon-header line-icon-header-1"></div>
      <div class="line-icon-header line-icon-header-2"></div>
      <div class="line-icon-header line-icon-header-3"></div>
    </div>
    <div
      class="wrapper-header-menu"
      :class="{'hide':!statusHeader}"
    >
      <div
        class="wrapper-icon-header"
        @click="statusHeader = !statusHeader"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.0596 1.44328L13.6163 0L7.53 6.08629L1.44371 0L0.000427246 1.44328L6.08672 7.52957L0.000427246 13.6159L1.44371 15.0591L7.53 8.97285L13.6163 15.0591L15.0596 13.6159L8.97328 7.52957L15.0596 1.44328Z" fill="white"/>
        </svg>
      </div>
      <div class="wrapper-logo-header">
        <img src="../assets/logo.png" alt="">
      </div>
      <div class="wrapper-main-buttons">
        <router-link to="/training-card">
          <DefaultButton
                  @press = 'statusHeader = !statusHeader'
                  :title="`View Your Training Card`"
          />
        </router-link>
        <router-link to="/certificates">
          <DefaultButton
                  @press = 'statusHeader = !statusHeader'
                  :title="`View Your Training Certificates`"
          />
        </router-link>


      </div>
      <div class="wrapper-menu-items">
        <div class="menu-item">
          <router-link
            :to="{ name: 'EditProfile'}"
            @click = 'statusHeader = !statusHeader'
          >
            Edit Profile
          </router-link>
        </div>
        <div class="menu-item">
          <router-link
            :to="{ name: 'Contact'}"
            @click = 'statusHeader = !statusHeader'
          >
            Contact
          </router-link>
        </div>
        <div class="menu-item">
          <a
            @click="logout()"
          >Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "./DefaultButton";
export default {
  name: 'Header',
  props: {
    btnBackShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DefaultButton,
  },
  data() {
    return {
      statusHeader: false
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
  .header {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #000000;
    .header-back-link {
      text-decoration: none;
      .wrapper-back {
        display: flex;
        align-items: center;
        span {
          font-weight: 500;
          font-size: 16px;
          margin-left: 10px;
          color: #000000;
        }
      }
    }
    .wrapper-icon-header {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      .line-icon-header {
        width: 20px;
        height: 2px;
        background-color: #000;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .wrapper-header-menu {
      position: fixed;
      z-index: 3;
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
      transition: 0.3s;
      background-color: #1F3E63;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      &.hide {
        transform: translateY(-120vh);
      }
      .wrapper-icon-header {
        margin-left: auto;
        justify-content: center;
        align-items: center;
        height: 15px;
      }
      .wrapper-logo-header {
        margin-top: 10px;
        margin-bottom: 32px;
        max-width: 550px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        img {
          width: 100%;
          max-width: 100%;
        }
      }
      .wrapper-main-buttons {
        max-width: 550px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        .default-btn {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
      .wrapper-menu-items {
        display: flex;
        flex-direction: column;
        max-width: 550px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        .menu-item {
          margin-bottom: 15px;
          cursor: pointer;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          a {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            text-decoration: none;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .header {
      .wrapper-header-menu {
        .wrapper-main-buttons {
          display: flex;
          justify-content: center;
          .default-btn {
            &:first-child {
              margin-right: 20px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
