<template>
  <div class="wrapper-notification" :class="notification.status">
      <div class="inside-wrapper" >
          <div class="wrapper-icon">
                <div class="icon">
                    <svg v-if="notification.status == 'success'" version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g><g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z"></path>
                            </g></g>
                    </svg>
                    <svg v-else version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 301.691 301.691" style="enable-background:new 0 0 301.691 301.691;" xml:space="preserve">
                        <g>
                            <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0  "></polygon>
                            <rect x="130.563" y="261.168" width="40.525" height="40.523"></rect>
                        </g>
                    </svg>
                </div>
          </div>
          <div class="wrapper-texts">
              <div class="title-notification">
                  {{notification.status}}
              </div>
              <div class="description-notification">
                    {{notification.message}}
              </div>
          </div>
          <div @click="closeNotification" class="wrapper-close-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
                  <path fill-rule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path>
              </svg>
          </div>
      </div>

  </div>
</template>

<script>
export default {
    name: 'Notification',
    props: {
        notification: {
        }
    },
    methods: {
        closeNotification() {
            this.$store.dispatch("closeNotification")
        }
    }
}
</script>

<style lang="scss">
  .wrapper-notification {
      position: fixed;
      z-index: 10;
      top: 15px;
      right: 15px;
      width: 300px;
      height: auto;
      background-color: white;
      box-shadow: 1px 7px 14px -5px #00000033;
      border-radius: 4px;
      padding: 20px 10px;
      border-left: 3px solid #2BDE3F;
      &.error {
          border-left: 3px solid red;
          .inside-wrapper {
              .wrapper-icon {
                  .icon {
                      background-color: red;
                  }
              }
              .wrapper-texts {
                  .title-notification {
                     color: red;
                  }

              }
          }
      }
      .inside-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          .wrapper-icon {
              margin-right: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                  width: 30px;
                  height: 30px;
                  background-color: #2BDE3F;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  svg {
                      height: 20px;
                      stroke: white;
                      fill: white;
                  }
              }
          }
          .wrapper-texts {
              .title-notification {
                  margin-bottom: 10px;
                  font-size: 16px;
                  text-transform: capitalize;
                  font-weight: 600;
                  color: #2BDE3F;
              }
              .description-notification {
                  font-size: 14px;
                  line-height: 140%;
              }
          }
          .wrapper-close-icon {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              z-index: 1;
              cursor: pointer;
              top: -10px;
              right: 0px;
          }
      }

  }
</style>
