<template>
  <div class="home">
      <Header
        :btnBackShow="true"
      >
          <router-link :to="{name: 'Home'}" class="header-back-link">
              <div class="wrapper-back">
                  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.71583L7.28417 0L0 7.28417L7.28417 14.5683L9 12.8525L3.43165 7.28417L9 1.71583Z" fill="black"/>
                  </svg>
                  <span class="text-back">Back</span>
              </div>
          </router-link>
      </Header>
      <div class="wrapper-content-contact">
			<DisabledOfflineBlock v-if="!online"/>
          <p class="title">
              Contact
          </p>
          <div class="wrapper-form-contact">
              <div class="form-element">
                  <label for="name">Name</label>
                  <input v-model="name" id="name" :class="(errors.name) ? 'is-invalid' : ''" type="text">
                  <div class="invalid-feedback">
                        {{(errors.name) ? errors.name[0] : ''}}
                  </div>
              </div>
              <div class="form-element">
                  <label for="email">Email</label>
                  <input v-model="email" id="email" :class="(errors.email) ? 'is-invalid' : ''" type="email">
                  <div class="invalid-feedback">
                      {{(errors.email) ? errors.email[0] : ''}}
                  </div>
              </div>
              <div class="form-element">
                  <label for="phone">Phone</label>
                  <input v-model="phone" id="phone" :class="(errors.phone) ? 'is-invalid' : ''" type="text">
                  <div class="invalid-feedback">
                      {{(errors.phone) ? errors.phone[0] : ''}}
                  </div>
              </div>
              <div class="form-element">
                  <label for="message">Message</label>
                  <textarea v-model="message" :class="(errors.message) ? 'is-invalid' : ''" name="message" id="message" cols="30" rows="10"></textarea>
                  <div class="invalid-feedback">
                      {{(errors.message) ? errors.message[0] : ''}}
                  </div>
              </div>
              <div class="form-element">
                  <label class="checkbox-control">
                      <input type="checkbox" v-model="agree" name="agree" />
                      MIDFIX collects personal information when you complete this form. This is used to provide the services requested, maintain our records and, if you agree, to send you marketing information. Please refer to our privacy policy to understand exactly how we use your information. I would like to receive further information about your products and services.
                      <div class="invalid-feedback">
                          Some validation
                      </div>
                  </label>
              </div>
          </div>
        <DefaultButton
            :title="`Submit`"
            @press="send"
            :class="{'disabled':!agree}"

        />
      </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import DefaultButton from "../components/DefaultButton";
import DisabledOfflineBlock from "../components/DisabledOfflineBlock";
export default {
    name: 'Contact',
    components: {
        Header,
        DefaultButton,
		DisabledOfflineBlock
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            agree: false,
        }
    },
    methods: {
        send() {
            this.$store.dispatch("showPreloader")
            this.$store.dispatch("clearErrors")
            let name = this.name;
            let email = this.email;
            let phone = this.phone;
            let message = this.message;
            this.$store.dispatch("sendMail", {
                    name, email, phone, message
                })
        }
    },
    computed: {
        notification() {
            return this.$store.state.notification
        },
        errors() {
            return this.$store.state.notification.data;
        },
		online() {
			return this.$store.state.online
		},
    },
    mounted() {
        this.$store.dispatch("clearErrors")
    }
}
</script>

<style lang="scss">
    .wrapper-content-contact {
        padding: 20px;
        background-color: #fff;
        min-height: 100vh;
		position: relative;
        .title {
            font-weight: 700;
            font-size: 24px;
            letter-spacing: -1px;
            color: #1B3E67;
            padding-bottom: 15px;
            border-bottom: 1px solid #000000;
        }

        .wrapper-form-contact {
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            max-width: 500px;
            .form-element {
                margin-top: 20px;
                label {
                    font-size: 14px;
                    line-height: 24px;
                    color: #1B3E67;
                }
                input {
                    border: 1px solid #1B3E67;
                    border-radius: 2px;
                    font-size: 14px;
                    line-height: 1.7;
                    padding: 10px;
                }
                textarea {
                    border: 1px solid #1B3E67;
                    font-size: 14px;
                    line-height: 1.7;
                    padding: 10px;
                }
                .checkbox-control {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000000;
                    display: flex;
                    input[type="checkbox"] {
                        appearance: none;
                        background-color: #fff;
                        margin: 0;
                        font: inherit;
                        color: #1B3E67;
                        width: 20px;
                        height: 20px;
                        border: 2px solid #1B3E67;
                        border-radius: 4px;
                    }
                    input[type="checkbox"] {
                        display: grid;
                        place-content: center;
                        margin-right: 7px;
                    }
                    input[type="checkbox"]::before {
                        content: "";
                        width: 16px;
                        height: 16px;
                        transform: scale(0);
                        transition: 120ms transform ease-in-out;
                        box-shadow: inset 16px 16px #FDC300;
                        background-color: #FDC300;
                    }
                    input[type="checkbox"]:checked::before {
                        transform: scale(1);
                    }
                    input[type="checkbox"]:focus {
                        outline: 1px solid transparent;
                        outline-offset: 1px;
                    }

                }
            }
        }

        .default-btn {
            margin-top: 20px;
            margin-bottom: 60px;
        }
    }
    @media screen and (min-width: 768px) {
        .wrapper-content-contact {
            padding: 40px;
            .default-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
</style>
